import React, { Component } from 'react'
import CountUp from 'react-countup'

export default class FormResponse extends Component {
  //
  render() {
    const { contributors, contributor, isEnglish } = this.props
    return (
      <div className="container">
        {isEnglish && (
          <p>
            Thank you for participating, {contributor.name}! As of now we have
            saved <CountUp separator=" " end={contributors.length * 10000} /> m²
            of rainforest—which is equal to {contributors.length * 1.25}{' '}
            football fields!
          </p>
        )}
        {!isEnglish && (
          <p>
            Tusen takk for at du ble på med dette, {contributor.name}! Så langt
            har vi reddet{' '}
            <CountUp separator=" " end={contributors.length * 10000} /> m² med
            regnskog – det tilsvarer {contributors.length * 1.25} fotballbaner!
          </p>
        )}
      </div>
    )
  }
}
