import React, { Component } from 'react'
import cc from 'classcat'
import uuid from 'uuid/v1'

import { addContributor } from '../utils/firebase'

class App extends Component {
  state = {
    name: '',
    company: '',
    canSubmit: false
  }

  handleChange = e => {
    const { name, company } = this.state
    this.setState({
      [e.target.name]: e.target.value
    })
    if (name.length > 0 && company.length > 0) {
      this.setState({
        canSubmit: true
      })
    }
  }
  handleSubmit = e => {
    e.preventDefault()
    const person = {
      id: uuid(),
      name: this.state.name,
      company: this.state.company,
      timestamp: Math.round(new Date().getTime() / 1000)
    }
    addContributor(person) // catch error
    this.setState({
      name: '',
      company: ''
    })
    this.props.setContributor(person)
    this.props.setSubmitted()
  }
  render() {
    const { name, company } = this.state
    const { isEnglish } = this.props
    return (
      <form className="Form">
        <div className="Form__block">
          <input
            type="text"
            name="name"
            placeholder={isEnglish ? 'First name' : 'Fornavn'}
            className="Form__input"
            value={name}
            onChange={this.handleChange}
          />
          <input
            type="text"
            name="company"
            placeholder={isEnglish ? 'Company' : 'Firma'}
            className="Form__input"
            value={company}
            onChange={this.handleChange}
          />
          <button
            className={cc({
              Form__submit: true,
              button: true,
              'button--is-disabled': !this.state.canSubmit
            })}
            onClick={this.handleSubmit}
          >
            {isEnglish ? 'Plant tree' : 'Plant tre'}
          </button>
        </div>
      </form>
    )
  }
}

export default App
