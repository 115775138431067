import React, { Component, createRef } from 'react'

export default class PartyMode extends Component {
  audio = createRef()
  engagePartyMode = () => {
    this.props.togglePartyMode()
    if (this.audio.current.paused) {
      this.audio.current.play()
    } else {
      this.audio.current.pause()
    }
  }
  render() {
    const { party } = this.props
    return (
      <>
        <audio
          className="visually-hidden"
          controls
          src="/roger-rivas.mp3"
          ref={this.audio}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
        <button onClick={this.engagePartyMode} className="PartyMode">
          {party ? '🔇' : '🎵'}
          {/* 🔊 */}
        </button>
      </>
    )
  }
}
