import React from 'react'

export default [
  <>
    <path
      d="M141.73,17.32,255.29,135.46H28.18L141.73,17.32m0-17.32L0,147.46H283.46L141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M141.73,153.07,255.32,271.46H28.14L141.73,153.07m0-17.34L0,283.46H283.46L141.73,135.73Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M141.73,26.83,264.05,271.46H19.42L141.73,26.83m0-26.83L0,283.46H283.46L141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M141.73,152.81,255.36,271.46H28.11L141.73,152.81m0-17.35L0,283.46H283.46l-141.73-148Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="212.6"
      y1="283.38"
      x2="212.6"
      y2="236.08"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M211.88,41.74l55.4,182.54H157.8L211.88,41.74M211.74,0l-70,236.32H283.46L211.74,0Z"
      fill="#231f20"
    />
    <line
      x1="70.87"
      y1="283.5"
      x2="70.87"
      y2="236.2"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M70.15,41.74l55.4,182.54H16.07L70.15,41.74M70,0,0,236.28H141.73L70,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="215.73"
      y1="283.46"
      x2="215.73"
      y2="13.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="67.07"
      y1="283.46"
      x2="67.07"
      y2="13.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="-5.47 96.83 67.07 9.38 141.37 96.77 215.73 9.23 288.93 96.77"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="-5.47 155.25 67.07 67.8 141.37 155.19 215.73 67.65 288.93 155.19"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="-5.47 213.68 67.07 126.23 141.37 213.61 215.73 126.08 288.93 213.61"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="-5.47 272.1 67.07 184.65 141.37 272.04 215.73 184.5 288.93 272.04"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <line
      x1="141.73"
      y1="283.46"
      x2="141.73"
      y2="214.05"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M206.05,12l63.15,98.3-63.15,98.29H77.42L14.26,110.3,77.42,12H206.05M212.6,0H70.87L0,110.3,70.87,220.59H212.6L283.46,110.3,212.6,0Z"
      fill="#231f20"
    />
    <path
      d="M178.54,12l40,98.3-40,98.29H104.92l-40-98.29,40-98.3h73.62m8.08-12H96.85L52,110.3,96.85,220.59h89.77L231.5,110.3,186.62,0Z"
      fill="#231f20"
    />
    <path
      d="M149.66,12l16.15,98.3-16.15,98.29H133.8L117.66,110.3,133.8,12h15.86M159.85,0H123.61L105.5,110.3l18.11,110.29h36.24L178,110.3,159.85,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.68"
      y1="285.71"
      x2="141.68"
      y2="135.98"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M70.87,134A58.83,58.83,0,1,1,12,192.83,58.91,58.91,0,0,1,70.87,134m0-12a70.83,70.83,0,1,0,70.86,70.82A70.85,70.85,0,0,0,70.87,122Z"
      fill="#231f20"
    />
    <path
      d="M212.54,134a58.83,58.83,0,1,1-58.86,58.82A58.91,58.91,0,0,1,212.54,134m0-12a70.83,70.83,0,1,0,70.87,70.82A70.84,70.84,0,0,0,212.54,122Z"
      fill="#231f20"
    />
    <path
      d="M141.68,12A58.82,58.82,0,1,1,82.81,70.82,58.91,58.91,0,0,1,141.68,12m0-12a70.82,70.82,0,1,0,70.86,70.82A70.85,70.85,0,0,0,141.68,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.73"
      y1="-0.18"
      x2="141.73"
      y2="283.29"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="-0.05"
      y1="141.56"
      x2="283.52"
      y2="141.56"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="272.42"
      y1="10.87"
      x2="11.05"
      y2="272.24"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="103.23"
      y1="44.56"
      x2="180.23"
      y2="238.56"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="183.1"
      y1="45.74"
      x2="100.37"
      y2="237.37"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="238.73"
      y1="103.06"
      x2="44.73"
      y2="180.06"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="237.55"
      y1="182.92"
      x2="45.92"
      y2="100.19"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="11.05"
      y1="10.87"
      x2="272.42"
      y2="272.24"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <line
      x1="141.65"
      y1="288.19"
      x2="141.75"
      y2="10.01"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.8,12.57a533.34,533.34,0,0,1,65.84,26c40.53,19.55,63.77,39.86,63.77,55.7s-23.28,36.15-63.87,55.71a537.56,537.56,0,0,1-65.93,26,536.06,536.06,0,0,1-65.84-26C35.24,130.38,12,110.08,12,94.23s23.28-36.14,63.87-55.7a534.78,534.78,0,0,1,65.94-26M141.8,0S0,42.19,0,94.23H0c0,52,141.62,94.23,141.62,94.23s141.8-42.19,141.8-94.23h0C283.41,42.19,141.8,0,141.8,0Z"
      fill="#231f20"
    />
    <path
      d="M141.8,62.56a534.07,534.07,0,0,1,65.6,25.85c40.68,19.59,64,39.94,64,55.82s-23.28,36.14-63.87,55.7a535.46,535.46,0,0,1-65.93,26A533.52,533.52,0,0,1,76,200.05c-40.69-19.6-64-39.94-64-55.82s23.28-36.15,63.87-55.71a538.79,538.79,0,0,1,65.94-26m0-12.56S0,92.19,0,144.23H0c0,52,141.62,94.23,141.62,94.23s141.8-42.19,141.8-94.23h0C283.41,92.19,141.8,50,141.8,50Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.66"
      y1="283.29"
      x2="141.66"
      y2="8.73"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.66,12c36.25,0,70.15,5.92,95.44,16.67,13.54,5.75,23.62,12.57,29.2,19.5H17c5.58-6.93,15.67-13.75,29.2-19.5C71.51,17.92,105.41,12,141.66,12m0-12C63.45,0,.05,26.94.05,60.17H283.27C283.27,26.94,219.87,0,141.66,0Z"
      fill="#231f20"
    />
    <path
      d="M141.54,60.33c36.25,0,70.14,5.92,95.44,16.67,13.53,5.75,23.61,12.57,29.2,19.5H16.89c5.59-6.93,15.67-13.75,29.2-19.5,25.3-10.75,59.2-16.67,95.45-16.67m0-12C63.32,48.33-.08,75.27-.08,108.5H283.15c0-33.23-63.4-60.17-141.61-60.17Z"
      fill="#231f20"
    />
    <path
      d="M141.54,108.41c36.25,0,70.14,5.92,95.44,16.67,13.53,5.75,23.61,12.57,29.2,19.5H16.89c5.59-6.93,15.67-13.75,29.2-19.5,25.3-10.75,59.2-16.67,95.45-16.67m0-12C63.32,96.41-.08,123.35-.08,156.58H283.15c0-33.23-63.4-60.17-141.61-60.17Z"
      fill="#231f20"
    />
    <path
      d="M141.66,156.51c36.25,0,70.15,5.92,95.44,16.67,13.54,5.75,23.62,12.57,29.2,19.5H17c5.58-6.93,15.67-13.75,29.2-19.5,25.29-10.75,59.19-16.67,95.44-16.67m0-12C63.45,144.51.05,171.45.05,204.68H283.27c0-33.23-63.4-60.17-141.61-60.17Z"
      fill="#231f20"
    />
    <path
      d="M141.66,204.57c36.25,0,70.15,5.92,95.44,16.67,13.54,5.75,23.62,12.58,29.2,19.51H17c5.58-6.93,15.67-13.76,29.2-19.51,25.29-10.75,59.19-16.67,95.44-16.67m0-12C63.45,192.57.05,219.51.05,252.75H283.27c0-33.24-63.4-60.18-141.61-60.18Z"
      fill="#231f20"
    />
  </>,
  <>
    <polyline
      points="289.37 165.26 141.71 9 -5.95 165.26"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="289.37 210.85 141.71 54.59 -5.95 210.85"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="289.37 256.44 141.71 100.17 -5.95 256.44"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="289.37 302.02 141.71 145.76 -5.95 302.02"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="234.15 288.13 142.69 191.35 49.27 290.22"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <line
      x1="141.71"
      y1="9"
      x2="141.71"
      y2="290.22"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <path
      d="M205.73,12l63.68,106.43L205.73,224.86H77.64L14,118.43,77.64,12H205.73m6.81-12H70.83L0,118.43,70.83,236.86H212.54l70.85-118.43L212.54,0Z"
      fill="#231f20"
    />
    <path
      d="M182.43,50.94l40.38,67.49-40.38,67.49h-81.5L60.56,118.43l40.37-67.49h81.5m6.81-12H94.13L46.58,118.43l47.55,79.49h95.11l47.55-79.49L189.24,38.94Z"
      fill="#231f20"
    />
    <line
      x1="141.68"
      y1="147.73"
      x2="141.68"
      y2="283.96"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M157.19,93.13l15.14,25.3-15.14,25.29h-31L111,118.43l15.14-25.3h31m6.81-12H119.37l-22.31,37.3,22.31,37.29H164l22.31-37.29L164,81.13Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M141.69,12A129.75,129.75,0,0,1,270.92,129.64H12.45A129.75,129.75,0,0,1,141.69,12m0-12A141.71,141.71,0,0,0-.09,141.64H283.46A141.71,141.71,0,0,0,141.69,0Z"
      fill="#231f20"
    />
    <path
      d="M141.69,153.82A129.75,129.75,0,0,1,270.92,271.46H12.45A129.75,129.75,0,0,1,141.69,153.82m0-12A141.71,141.71,0,0,0-.09,283.46H283.46A141.71,141.71,0,0,0,141.69,141.82Z"
      fill="#231f20"
    />
    <line
      x1="141.52"
      y1="278.92"
      x2="141.52"
      y2="4.36"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.52,12c13.94,0,28.28,12.7,39.36,34.85C192,69.16,198.74,98.2,200.11,129.64H82.94C84.3,98.2,91,69.16,102.16,46.85,113.24,24.7,127.59,12,141.52,12m0-12C102.4,0,70.68,63.42,70.68,141.64H212.36C212.36,63.42,180.65,0,141.52,0Z"
      fill="#231f20"
    />
    <path
      d="M141.52,153.82c13.94,0,28.28,12.7,39.36,34.86s17.86,51.35,19.23,82.78H82.94C84.3,240,91,211,102.16,188.68s25.43-34.86,39.36-34.86m0-12c-39.12,0-70.84,63.42-70.84,141.64H212.36c0-78.22-31.71-141.64-70.84-141.64Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M47.31,12c1,.6,4.73,3.38,9.64,14.17,4.82,10.6,9.37,25.89,13.15,44.21C77.58,106.73,82,153.46,82.56,203.08H64.32l.61,12.59c.88,18.05,1.4,36.8,1.55,55.88H28.15c.15-19.08.67-37.83,1.55-55.88l.61-12.59H12.07c.61-49.62,5-96.35,12.46-132.67C28.31,52.09,32.86,36.8,37.68,26.2,42.59,15.41,46.29,12.63,47.31,12m0-12.12C21.18-.09,0,96.25,0,215.08H17.71c-1,21.51-1.61,44.53-1.61,68.47H78.53c0-23.94-.57-47-1.61-68.47H94.63C94.63,96.25,73.45-.09,47.31-.09Z"
      fill="#231f20"
    />
    <path
      d="M141.73,12c1,.6,4.73,3.38,9.64,14.17,4.82,10.6,9.37,25.89,13.14,44.21C172,106.73,176.37,153.46,177,203.08H158.74l.61,12.59c.88,18.05,1.4,36.8,1.55,55.88H122.56c.16-19.08.68-37.83,1.56-55.88l.61-12.59H106.49c.61-49.62,5-96.35,12.46-132.67,3.78-18.32,8.32-33.61,13.14-44.21C137,15.41,140.7,12.63,141.73,12m0-12.12c-26.13,0-47.31,96.34-47.31,215.17h17.71c-1,21.51-1.61,44.53-1.61,68.47H173c0-23.94-.57-47-1.62-68.47h17.72C189.05,96.25,167.86-.09,141.73-.09Z"
      fill="#231f20"
    />
    <path
      d="M236.15,12c1,.6,4.72,3.38,9.64,14.17,4.82,10.6,9.36,25.89,13.14,44.21,7.48,36.32,11.85,83.05,12.46,132.67H253.15l.61,12.59c.88,18.05,1.4,36.8,1.56,55.88H217c.15-19.08.67-37.83,1.55-55.88l.62-12.59H200.91c.61-49.62,5-96.35,12.46-132.67,3.77-18.32,8.32-33.61,13.14-44.21,4.91-10.79,8.61-13.57,9.64-14.17m0-12.12c-26.13,0-47.32,96.34-47.32,215.17h17.72c-1.05,21.51-1.62,44.53-1.62,68.47h62.44c0-23.94-.57-47-1.62-68.47h17.71C283.46,96.25,262.28-.09,236.15-.09Z"
      fill="#231f20"
    />
    <line
      x1="2.56"
      y1="208.37"
      x2="273.06"
      y2="208.37"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="236.15"
      y1="208.37"
      x2="236.15"
      y2="6.33"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="141.73"
      y1="205.37"
      x2="141.73"
      y2="3.32"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="47.31"
      y1="208.37"
      x2="47.31"
      y2="6.33"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <path
      d="M141.69,12A82.88,82.88,0,1,1,58.92,94.88,82.92,82.92,0,0,1,141.69,12m0-12a94.88,94.88,0,1,0,94.78,94.88A94.83,94.83,0,0,0,141.69,0Z"
      fill="#231f20"
    />
    <line
      x1="141.69"
      y1="7.43"
      x2="141.69"
      y2="283.87"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="230.38"
      y1="94.88"
      x2="53.01"
      y2="94.88"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="204.99"
      y1="31.51"
      x2="78.4"
      y2="158.25"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="205.69"
      y1="158.94"
      x2="77.7"
      y2="30.82"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M13.86,190.49l106.25,80.4a64.24,64.24,0,0,1-8.78.57c-21.56,0-47.59-9.56-67.93-24.94C24,231.85,12,213.72,12,199.2a19.42,19.42,0,0,1,1.88-8.71m-1.57-16.24C-11,191.86-.29,228.5,36.16,256.09c23.4,17.7,51.73,27.37,75.17,27.37,13.08,0,24.64-3,33-9.31l-132-99.9Z"
      fill="#231f20"
    />
    <path
      d="M269.58,190.49a19.42,19.42,0,0,1,1.88,8.71c0,14.52-12,32.65-31.42,47.32-20.33,15.38-46.36,24.94-67.93,24.94a64.34,64.34,0,0,1-8.78-.57l106.25-80.4m1.57-16.24-132,99.9c8.34,6.3,19.9,9.31,33,9.31,23.45,0,51.77-9.67,75.17-27.37,36.46-27.59,47.14-64.23,23.87-81.84Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.51"
      x2="141.51"
      y2="283.32"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="92.75"
      y1="36.79"
      x2="190.28"
      y2="36.79"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="59.45"
      y1="96.54"
      x2="223.57"
      y2="96.54"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="28.58"
      y1="156.29"
      x2="254.44"
      y2="156.29"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="283.46"
      y1="216.04"
      x2="-0.09"
      y2="216.04"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="92.75"
      y1="66.67"
      x2="190.28"
      y2="66.67"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="283.46"
      y1="245.92"
      x2="-0.09"
      y2="245.92"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="59.45"
      y1="126.42"
      x2="223.57"
      y2="126.42"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="28.58"
      y1="186.17"
      x2="254.44"
      y2="186.17"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <path
      d="M269.9,102.52c-4,15.49-15.92,30-34.4,41.81-24.87,15.86-58.17,24.59-93.77,24.59S72.83,160.19,48,144.33C29.48,132.54,17.62,118,13.56,102.52H269.9m13.56-12H0c0,49.93,63.46,90.4,141.73,90.4s141.73-40.47,141.73-90.4Z"
      fill="#231f20"
    />
    <path
      d="M269.9,12c-4,15.49-15.92,30-34.4,41.81-24.87,15.86-58.17,24.6-93.77,24.6S72.83,69.67,48,53.81C29.48,42,17.62,27.49,13.56,12H269.9M283.46,0H0C0,49.93,63.46,90.41,141.73,90.41S283.46,49.93,283.46,0Z"
      fill="#231f20"
    />
    <line
      x1="144.32"
      y1="2.9"
      x2="144.32"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="81.79"
      y1="2.9"
      x2="81.79"
      y2="72.39"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="206.85"
      y1="3.66"
      x2="206.85"
      y2="73.15"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="81.79"
      y1="94.52"
      x2="81.79"
      y2="164.01"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="206.85"
      y1="95.28"
      x2="206.85"
      y2="164.77"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <line
      x1="141.56"
      y1="1.19"
      x2="141.56"
      y2="286.56"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="279.59"
      y1="5.36"
      x2="141.56"
      y2="286.56"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="3.55"
      y1="5.36"
      x2="141.56"
      y2="286.56"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="139.74"
      y1="286.56"
      x2="215.05"
      y2="1"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="143.39"
      y1="286.56"
      x2="68.07"
      y2="1"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="-0.17"
      y1="5.94"
      x2="283.31"
      y2="5.94"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <path
      d="M141.73,17.74c2.32,5.81,5.39,16.18,8.24,34.22,3.94,25,6.11,56.9,6.11,89.9s-2.17,64.92-6.11,89.9c-2.85,18-5.92,28.41-8.24,34.22-2.31-5.81-5.39-16.18-8.24-34.22-3.93-25-6.1-56.9-6.1-89.9s2.17-64.93,6.1-89.9c2.85-18,5.93-28.41,8.24-34.22m0-17.74c-14.55,0-26.34,63.51-26.34,141.86s11.79,141.86,26.34,141.86,26.35-63.52,26.35-141.86S156.28,0,141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M257.78,131.29c-.61,1-1.24,2.08-1.91,3.18-10.63,17.43-25.43,38.53-41.68,59.42S181.14,234.34,166.86,249l-2.61,2.65c.61-1,1.24-2.08,1.91-3.18C176.79,231,191.59,209.89,207.84,189s33-40.45,47.33-55.06l2.61-2.65m22.79-32.11c-10.17,0-45.41,35.12-82.21,82.46C159,232.3,132.7,277.77,139.69,283.19a2.84,2.84,0,0,0,1.77.53c10.17,0,45.41-35.13,82.21-82.46,39.39-50.67,65.66-96.14,58.67-101.55a2.78,2.78,0,0,0-1.77-.53Z"
      fill="#231f20"
    />
    <path
      d="M25.68,131.29l2.62,2.65C42.57,148.55,59.38,168.1,75.63,189s31,42,41.67,59.42c.67,1.1,1.31,2.15,1.92,3.18L116.6,249c-14.27-14.61-31.08-34.16-47.33-55.06s-31-42-41.67-59.42c-.67-1.1-1.31-2.15-1.92-3.18M2.89,99.18a2.77,2.77,0,0,0-1.76.53c-7,5.41,19.28,50.88,58.67,101.55,36.8,47.33,72,82.46,82.2,82.46a2.84,2.84,0,0,0,1.77-.53c7-5.42-19.28-50.89-58.67-101.55-36.8-47.34-72-82.46-82.21-82.46Z"
      fill="#231f20"
    />
    <path
      d="M215.3,106.51c-.33,1.16-.68,2.35-1.05,3.59-5.82,19.56-14.72,43.74-25.07,68.09s-21.6,47.54-31.67,65.3c-.63,1.13-1.26,2.22-1.86,3.26.33-1.15.68-2.35,1-3.59,5.81-19.56,14.72-43.74,25.07-68.09s21.6-47.54,31.66-65.3c.64-1.13,1.26-2.22,1.87-3.26m14.69-37c-9.13,0-35.06,43.92-59.26,100.83-25.11,59-38.87,109.7-30.72,113.15a2.3,2.3,0,0,0,1,.19c9.13,0,35-43.93,59.26-100.84,25.11-59,38.86-109.7,30.72-113.15a2.47,2.47,0,0,0-.95-.18Z"
      fill="#231f20"
    />
    <path
      d="M64.09,106.51c.61,1,1.23,2.13,1.87,3.26,10.06,17.76,21.31,41,31.66,65.3s19.26,48.53,25.08,68.09c.37,1.24.72,2.44,1,3.59-.61-1-1.23-2.13-1.87-3.26-10.06-17.76-21.3-41-31.66-65.3S71,129.66,65.14,110.1l-1-3.59m-14.68-37a2.56,2.56,0,0,0-1,.18c-8.14,3.45,5.62,54.12,30.73,113.15,24.2,56.91,50.12,100.84,59.26,100.84a2.34,2.34,0,0,0,.95-.19c8.14-3.45-5.61-54.11-30.72-113.15C84.46,113.47,58.54,69.55,49.41,69.55Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M141.17,12c10.11,0,19.86,5.74,27.46,16.15,8.43,11.55,13.08,27.1,13.08,43.79s-4.65,32.25-13.08,43.8c-7.6,10.41-17.35,16.15-27.46,16.15s-19.85-5.74-27.45-16.15c-8.44-11.55-13.08-27.1-13.08-43.8s4.64-32.24,13.08-43.79C121.32,17.74,131.07,12,141.17,12m0-12c-29,0-52.53,32.21-52.53,71.94s23.52,71.95,52.53,71.95,52.54-32.21,52.54-71.95S170.19,0,141.17,0Z"
      fill="#231f20"
    />
    <path
      d="M251.53,20.7v12c7.25,0,13.13,1.34,16.54,3.78,2.38,1.7,3.39,3.79,3.39,7,0,11.57-13.81,33.89-44.15,55.54-28,20-61.19,32.86-84.59,32.86-7.25,0-13.12-1.35-16.54-3.78-2.38-1.7-3.39-3.79-3.39-7,0-11.57,13.82-33.89,44.16-55.54,28-20,61.18-32.86,84.58-32.86v-12m0,0c-25.05,0-60.51,13-91.55,35.09-43,30.7-61.28,67.45-40.77,82.09,5.72,4.08,13.84,6,23.51,6,25.05,0,60.51-12.95,91.55-35.09,43-30.7,61.29-67.45,40.77-82.09-5.71-4.08-13.84-6-23.51-6Z"
      fill="#231f20"
    />
    <path
      d="M31.79,32.14c23.49,0,56.9,13,85.12,33.14,30.61,21.84,44.56,44.3,44.56,55.93,0,3.18-1,5.25-3.35,6.93-3.39,2.42-9.23,3.75-16.44,3.75-23.49,0-56.9-13-85.13-33.15C25.94,76.91,12,54.44,12,42.82c0-3.19,1-5.26,3.35-6.93,3.39-2.42,9.22-3.75,16.44-3.75h0m0-12c-9.64,0-17.72,1.92-23.41,6-20.52,14.63-2.07,51.52,41.21,82.39,31.27,22.32,67,35.38,92.09,35.38,9.63,0,17.72-1.93,23.41-6,20.51-14.64,2.06-51.53-41.21-82.4C92.6,33.2,56.92,20.14,31.79,20.14Z"
      fill="#231f20"
    />
    <line
      x1="141.18"
      y1="139.85"
      x2="141.18"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M251.58,134.36v12c7.23,0,13.09,1.34,16.5,3.77,2.37,1.7,3.38,3.8,3.38,7,0,11.58-13.79,33.91-44.09,55.55-27.94,20-61.09,32.85-84.45,32.85-7.24,0-13.1-1.34-16.5-3.77-2.38-1.69-3.39-3.79-3.39-7,0-11.58,13.8-33.9,44.1-55.54,27.94-20,61.08-32.86,84.44-32.86v-12m0,0c-25,0-60.42,12.95-91.42,35.09-43,30.7-61.2,67.45-40.71,82.09,5.71,4.07,13.82,6,23.48,6,25,0,60.43-12.94,91.42-35.09,43-30.69,61.2-67.44,40.71-82.08-5.7-4.08-13.81-6-23.48-6Z"
      fill="#231f20"
    />
    <path
      d="M31.87,145.8c23.45,0,56.8,13,85,33.13,30.57,21.84,44.5,44.31,44.5,55.94,0,4-1.7,5.75-3.35,6.93-3.38,2.42-9.2,3.74-16.4,3.74-23.45,0-56.81-13-85-33.13C26,190.57,12.12,168.11,12.12,156.48c0-4,1.69-5.76,3.35-6.94,3.37-2.41,9.2-3.74,16.4-3.74h0m0-12c-9.63,0-17.7,1.92-23.38,6C-12,154.41,6.42,191.3,49.64,222.17c31.23,22.32,66.87,35.37,92,35.37,9.62,0,17.7-1.92,23.38-6,20.48-14.64,2.06-51.53-41.15-82.4C92.59,146.86,57,133.8,31.87,133.8Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.69"
      y1="46.08"
      x2="141.69"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.73,12c36.44,0,70.52,5.31,96,15,11.47,4.35,20.66,9.55,26.55,14.77h-245C25.1,36.51,34.3,31.31,45.77,27c25.44-9.65,59.52-15,96-15m0-12C63.46,0,0,24.06,0,53.73H283.46C283.46,24.06,220,0,141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M165.59,75.52v12h0c15.18,0,33.78,9.16,48.54,23.91s23.92,33.31,23.92,48.46a32,32,0,0,1-.37,5l-77.07-77a32,32,0,0,1,5-.38v-12m0,0c-9.92,0-18.69,3-25,9.33l100.13,100c17.66-17.63,9.55-54.31-18.1-81.93-17.75-17.73-39.24-27.42-57-27.42Z"
      fill="#231f20"
    />
    <path
      d="M119.48,87.52a32,32,0,0,1,5,.38l-77.07,77a32,32,0,0,1-.37-5c0-15.15,9.16-33.72,23.92-48.46s33.36-23.91,48.54-23.91m0-12c-17.78,0-39.27,9.69-57,27.42-27.65,27.62-35.76,64.3-18.1,81.93l100.13-100c-6.32-6.31-15.09-9.33-25-9.33Z"
      fill="#231f20"
    />
    <path
      d="M164.79,157.23v12h0c15.18,0,33.77,9.16,48.54,23.9s23.92,33.31,23.92,48.47a32.26,32.26,0,0,1-.38,5l-77.07-77a33.27,33.27,0,0,1,5-.37v-12m0,0c-9.92,0-18.69,3-25,9.33l100.13,100c17.65-17.63,9.55-54.32-18.1-81.94-17.75-17.73-39.24-27.41-57-27.41Z"
      fill="#231f20"
    />
    <path
      d="M118.68,169.23a33.24,33.24,0,0,1,5,.37l-77.07,77a32.26,32.26,0,0,1-.38-5c0-15.16,9.17-33.73,23.93-48.47s33.36-23.9,48.54-23.9m0-12c-17.79,0-39.27,9.68-57,27.41C34,212.26,25.9,249,43.55,266.58l100.14-100c-6.32-6.31-15.09-9.33-25-9.33Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="27.5"
      x2="27.5"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="4.73 15.74 27.23 38.24 50.27 15.21"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="4.73 92.19 27.23 114.7 50.27 91.67"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="4.73 168.65 27.23 191.16 50.27 168.13"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="4.73 245.11 27.23 267.62 50.27 244.58"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <line
      x1="103.6"
      x2="103.6"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="126.37 226.69 103.86 204.19 80.83 227.22"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="126.37 147.59 103.86 125.09 80.83 148.12"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="126.37 68.5 103.86 45.99 80.83 69.03"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <line
      x1="179.7"
      x2="179.7"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="156.92 15.74 179.43 38.24 202.47 15.21"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="156.92 92.19 179.43 114.7 202.47 91.67"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="156.92 168.65 179.43 191.16 202.47 168.13"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="156.92 245.11 179.43 267.62 202.47 244.58"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <line
      x1="256.23"
      x2="256.23"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="279 226.69 256.5 204.19 233.46 227.22"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="279 147.59 256.5 125.09 233.46 148.12"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="279 68.5 256.5 45.99 233.46 69.03"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
  </>,
  <>
    <path
      d="M141.72,189.16c33.29,0,65.39,2.59,90.38,7.28,21.43,4,32.38,8.63,37.1,11.52-4.72,2.89-15.67,7.5-37.1,11.52-25,4.69-57.09,7.27-90.38,7.27s-65.39-2.58-90.38-7.27c-21.43-4-32.38-8.63-37.1-11.52,4.72-2.89,15.67-7.5,37.1-11.52,25-4.69,57.09-7.28,90.38-7.28m0-12C63.45,177.16,0,191,0,208s63.45,30.79,141.72,30.79S283.44,225,283.44,208s-63.45-30.8-141.72-30.8Z"
      fill="#231f20"
    />
    <line
      x1="141.43"
      y1="3.95"
      x2="141.43"
      y2="283.9"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.72,100.58c25.15,0,49.31,2.57,68,7.23,17.31,4.31,24.92,9.2,27.29,11.57-2.37,2.36-10,7.25-27.29,11.56-18.71,4.66-42.87,7.23-68,7.23s-49.31-2.57-68-7.23c-17.31-4.31-24.91-9.2-27.28-11.56,2.37-2.37,10-7.26,27.28-11.57,18.72-4.66,42.88-7.23,68-7.23m0-12c-59.6,0-107.92,13.79-107.92,30.8s48.32,30.79,107.92,30.79,107.91-13.79,107.91-30.79-48.31-30.8-107.91-30.8Z"
      fill="#231f20"
    />
    <path
      d="M141.72,12c19.6,0,38.61,3,52.15,8.22,12.81,4.94,15.71,9.87,15.71,10.57s-2.9,5.64-15.71,10.58c-13.54,5.22-32.55,8.22-52.15,8.22s-38.61-3-52.15-8.22C76.76,36.43,73.86,31.5,73.86,30.79s2.9-5.63,15.71-10.57C103.11,15,122.12,12,141.72,12m0-12C97.61,0,61.86,13.79,61.86,30.79s35.75,30.8,79.86,30.8,79.86-13.79,79.86-30.8S185.82,0,141.72,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M160.56,12V271.9H122.9V12h37.66m12-12H110.9V283.9h61.66V0Z"
      fill="#231f20"
    />
    <line
      x1="117.87"
      y1="227.51"
      x2="166.87"
      y2="227.51"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="117.87"
      y1="171.11"
      x2="166.87"
      y2="171.11"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="114.78"
      y1="114.72"
      x2="169.96"
      y2="114.72"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="114.63"
      y1="58.33"
      x2="170.11"
      y2="58.33"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M227.12,18.32l29.85,34H198l29.13-34M227,0l-55.1,64.33H283.46L227,0Z"
      fill="#231f20"
    />
    <path
      d="M227.12,74.72l29.85,34H198l29.13-34M227,56.41l-55.1,64.32H283.46L227,56.41Z"
      fill="#231f20"
    />
    <path
      d="M227.12,131.13l29.85,34H198l29.13-34M227,112.81l-55.1,64.33H283.46L227,112.81Z"
      fill="#231f20"
    />
    <path
      d="M227.12,187.53l29.85,34H198l29.13-34M227,169.22l-55.1,64.32H283.46L227,169.22Z"
      fill="#231f20"
    />
    <path
      d="M55.21,18.32l29.85,34h-59l29.13-34M55.1,0,0,64.33H111.56L55.1,0Z"
      fill="#231f20"
    />
    <path
      d="M55.21,74.72l29.85,34h-59l29.13-34M55.1,56.41,0,120.73H111.56L55.1,56.41Z"
      fill="#231f20"
    />
    <path
      d="M55.21,131.13l29.85,34h-59l29.13-34m-.11-18.32L0,177.14H111.56L55.1,112.81Z"
      fill="#231f20"
    />
    <path
      d="M55.21,187.53l29.85,34h-59l29.13-34m-.11-18.31L0,233.54H111.56L55.1,169.22Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M141.72,12c66.19,0,121,37.89,128.77,86.65H228.07l19.1,20.23c12.77,13.55,20.7,29,23.32,45.32H228.07l19.09,20.23c12.78,13.55,20.71,29,23.33,45.32H13c2.62-16.3,10.55-31.77,23.33-45.32L55.37,164.2H13c2.62-16.3,10.55-31.77,23.33-45.32L55.37,98.65H13C20.76,49.89,75.53,12,141.72,12m0-12C63.45,0,0,49.54,0,110.65H27.55C10.24,129,0,151.66,0,176.2H27.55C10.24,194.55,0,217.21,0,241.75H283.44c0-24.54-10.24-47.2-27.55-65.55h27.55c0-24.54-10.24-47.21-27.55-65.55h27.55C283.44,49.54,220,0,141.72,0Z"
      fill="#231f20"
    />
    <line
      x1="141.72"
      x2="141.72"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.72,12c17.18,0,33.6,9.73,46.24,27.39,11.49,16.06,18.59,36.77,20.46,59.26H185.63l9.27,17.59a125.27,125.27,0,0,1,13.52,48H185.63l9.27,17.59a125.27,125.27,0,0,1,13.52,48H75a125.27,125.27,0,0,1,13.52-48l9.27-17.59H75a125.27,125.27,0,0,1,13.52-48l9.27-17.59H75c1.87-22.49,9-43.2,20.46-59.26C108.12,21.73,124.55,12,141.72,12m0-12C98,0,62.53,49.54,62.53,110.65H77.92c-9.67,18.34-15.39,41-15.39,65.55H77.92c-9.67,18.35-15.39,41-15.39,65.55H220.91c0-24.54-5.72-47.2-15.39-65.55h15.39c0-24.54-5.72-47.21-15.39-65.55h15.39C220.91,49.54,185.46,0,141.72,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.41"
      y1="53.63"
      x2="141.41"
      y2="283.63"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M209.43,11.84c17.26,0,33.35,4.78,45.3,13.46,6.55,4.76,11.32,10.34,14,16.33H150.08c2.73-6,7.5-11.57,14.06-16.33,12-8.68,28-13.46,45.29-13.46m0-12c-40.89,0-74,24.08-74,53.79H283.46c0-29.71-33.14-53.79-74-53.79Z"
      fill="#231f20"
    />
    <path
      d="M209.43,176.84c17.26,0,33.35,4.78,45.3,13.46,6.55,4.76,11.32,10.34,14,16.33H150.08c2.73-6,7.5-11.57,14.06-16.33,12-8.68,28-13.46,45.29-13.46m0-12c-40.89,0-74,24.08-74,53.79H283.46c0-29.71-33.14-53.79-74-53.79Z"
      fill="#231f20"
    />
    <path
      d="M73.62,94.28c17.13,0,33.1,4.78,45,13.47,6.54,4.79,11.29,10.41,14,16.44H14.64c2.72-6,7.47-11.65,14-16.44,11.86-8.69,27.83-13.47,45-13.47m0-12C33,82.28,0,106.41,0,136.19H147.23c0-29.78-33-53.91-73.61-53.91Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M117.1,11.84V240.13H12V11.84H117.1m12-12H0V252.13H129.1V-.16Z"
      fill="#231f20"
    />
    <path
      d="M271.46,71.41V240.13H167.13V71.41H271.46m12-12H155.13V252.13H283.46V59.41Z"
      fill="#231f20"
    />
    <line
      x1="64.65"
      y1="247.13"
      x2="64.65"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="219.68"
      y1="247.13"
      x2="219.68"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="124.42 86.28 65.9 32.55 7.38 86.28"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="124.42 159.82 65.9 106.09 7.38 159.82"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="124.42 233.35 65.9 179.62 7.38 233.35"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="278.2 142.31 219.68 88.58 161.16 142.31"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="278.2 222.96 219.68 169.23 161.16 222.96"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
  </>,
  <>
    <path
      d="M141.84,12.91a470.49,470.49,0,0,1,64.55,32.31c19.61,12,35.14,24,46.13,35.88,12.56,13.5,18.92,26.39,18.92,38.31s-6.34,24.73-18.84,38.19c-11,11.82-26.45,23.87-46,35.82a473.09,473.09,0,0,1-64.93,32.48A472,472,0,0,1,76.8,193.43c-19.54-12-35-24-46-35.82C18.36,144.14,12,131.29,12,119.41s6.34-24.73,18.85-38.2c11-11.81,26.45-23.86,46-35.82a472.47,472.47,0,0,1,64.94-32.48m0-12.91S0,53.46,0,119.41H0c0,65.94,141.62,119.4,141.62,119.4s141.8-53.46,141.8-119.4h0C283.44,53.46,141.84,0,141.84,0Z"
      fill="#231f20"
    />
    <line
      x1="142.15"
      y1="8.89"
      x2="142.15"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="12.79 97.11 142.15 183.66 271.68 95.87"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="51.1 52.77 143.24 114.43 231.64 54.52"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
  </>,
  <>
    <path
      d="M152.31,52.76l14.62,65H116.87l14.62-65h20.82m9.6-12h-40l-20,89h80.06l-20-89Z"
      fill="#231f20"
    />
    <path
      d="M152.31,129.76l14.62,65H116.87l14.62-65h20.82m9.6-12h-40l-20,89h80.06l-20-89Z"
      fill="#231f20"
    />
    <path
      d="M152.5,206.82l14.59,64.64h-50l14.59-64.64H152.5m9.59-12h-40l-20,88.64H182.1l-20-88.64Z"
      fill="#231f20"
    />
    <path
      d="M212.6,12c16.62,0,32.7,3.77,44.13,10.34a42.63,42.63,0,0,1,8.66,6.42H159.8a42.71,42.71,0,0,1,8.67-6.42C179.9,15.77,196,12,212.6,12m0-12c-39.14,0-70.87,18.25-70.87,40.76H283.46C283.46,18.25,251.74,0,212.6,0Z"
      fill="#231f20"
    />
    <path
      d="M162.7,44.24c14.31.44,33.24,10.69,48.57,26.36C228,87.67,236.61,107,237.14,120.37L162.7,44.24m-.95-12c-8.15,0-15.18,2.43-20.19,7.56L241.78,142.27c15.56-15.91,5.74-51.76-21.93-80.06-18.77-19.19-40.92-30-58.1-30Z"
      fill="#231f20"
    />
    <path
      d="M70.87,12c16.61,0,32.7,3.77,44.12,10.34a42.44,42.44,0,0,1,8.67,6.42H18.07a42.44,42.44,0,0,1,8.67-6.42C38.16,15.77,54.25,12,70.87,12m0-12C31.73,0,0,18.25,0,40.76H141.73C141.73,18.25,110,0,70.87,0Z"
      fill="#231f20"
    />
    <path
      d="M120.41,44.24,46,120.38a46,46,0,0,1,1.7-10.32C51.22,97.06,60,82.68,71.84,70.6c15.33-15.67,34.26-25.92,48.57-26.36m1-12c-17.17,0-39.33,10.79-58.1,30-27.67,28.3-37.49,64.15-21.92,80.06L141.56,39.79c-5-5.13-12.05-7.56-20.2-7.56Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M141.67,283.46V263.33c-24,0-43.46-13.61-43.46-30.4s19.46-30.4,43.46-30.4,43.45-13.61,43.45-30.4-19.45-30.4-43.45-30.4-43.46-13.61-43.46-30.4,19.46-30.4,43.46-30.4,43.45-13.61,43.45-30.4-19.45-30.39-43.45-30.39V0"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M233.9,283.46V263.33c-24,0-43.45-13.61-43.45-30.4s19.45-30.4,43.45-30.4,43.46-13.61,43.46-30.4-19.46-30.4-43.46-30.4-43.45-13.61-43.45-30.4,19.45-30.4,43.45-30.4,43.46-13.61,43.46-30.4S257.9,20.14,233.9,20.14V0"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M49.44,283.46V263.33c-24,0-43.46-13.61-43.46-30.4s19.46-30.4,43.46-30.4,43.45-13.61,43.45-30.4-19.45-30.4-43.45-30.4S6,128.12,6,111.33s19.46-30.4,43.46-30.4,43.45-13.61,43.45-30.4S73.44,20.14,49.44,20.14V0"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <path
      d="M141.78,12.27h.1c35.62.11,69,8.31,94,23.1,17.86,10.56,29.52,23.35,33.88,36.89l-14.5,0-34.19-.09,26.63,21.44c8.29,6.67,17.79,16.42,21.88,28.32l-14.22.07-34.22.18L248,143.37c12.86,10.18,19,20.28,21.76,28.88H222.09l26.52,21.35c8.05,6.48,17.28,16.13,21.17,28.39H222.09l26.52,21.35c8.05,6.48,17.28,16.13,21.17,28.39H13.8c3.89-12.26,13.12-21.91,21.16-28.39L61.49,222H13.8C17.69,209.73,26.92,200.08,35,193.6l26.53-21.35H13.81c2.73-8.6,8.9-18.7,21.76-28.88L62.4,122.14,28.19,122,14,121.89c4.09-11.9,13.59-21.65,21.88-28.32L62.48,72.13l-34.19.09-14.5,0c4.36-13.54,16-26.33,33.87-36.89,25-14.79,58.42-23,94-23.1h.09m.14-12h-.26C63.79.51-.14,38.07-.14,84.3l28.46-.08C11,98.14-.08,115.2-.08,133.81l28.21.15C10.41,148-.08,165.39-.08,184.25H27.44C10.14,198.17-.08,215.37-.08,234H27.44C10.14,247.91-.08,265.11-.08,283.73H283.66c0-18.62-10.23-35.82-27.52-49.74h27.52c0-18.62-10.23-35.82-27.52-49.74h27.52c0-18.86-10.5-36.27-28.21-50.29l28.21-.15c0-18.61-11.11-35.67-28.41-49.59l28.47.08c0-46.23-63.93-83.79-141.8-84Z"
      fill="#231f20"
    />
    <path
      d="M141.69,12c1.16.32,6.34,4.35,11.21,18.51C156.84,42,159.38,56.4,160.24,72h-9.36L155,87.09a169.75,169.75,0,0,1,5.26,34.6h-9.38L155,136.88A168.66,168.66,0,0,1,160.43,172h-9.35l4.1,15.13a169.77,169.77,0,0,1,5.25,34.61h-9.35l4.1,15.13a169.78,169.78,0,0,1,5.25,34.6H123.34a169.78,169.78,0,0,1,5.25-34.6l4.1-15.13h-9.35a169.77,169.77,0,0,1,5.25-34.61l4.1-15.13h-9.58a170.59,170.59,0,0,1,5.29-35.16l4.1-15.14h-9.36a170.45,170.45,0,0,1,5.26-34.6L132.5,72h-9.35c.86-15.56,3.39-30,7.34-41.44,4.87-14.16,10-18.19,11.2-18.51m0-12c-17,0-30.87,37.59-30.87,84h6c-3.77,13.92-6,31.12-6,49.73h6c-3.77,13.93-6,31.13-6,49.74H111V184h6c-3.77,13.92-6,31.12-6,49.74h6c-3.77,13.92-6,31.12-6,49.73h61.75c0-18.61-2.23-35.81-6-49.73h6c0-18.62-2.23-35.82-6-49.74h6c0-18.87-2.29-36.28-6.15-50.3h6c0-18.61-2.23-35.81-6-49.73h6c0-46.37-13.83-84-30.88-84Z"
      fill="#231f20"
    />
    <path
      d="M141.79,12.27c33.1.13,60.66,26.09,66,60H182.39l13.09,18.85A73.87,73.87,0,0,1,207.81,122H182.27l13.38,18.93a73.66,73.66,0,0,1,12.65,31.36H182.88L196,191.1A73.91,73.91,0,0,1,208.3,222H182.88L196,240.84a73.86,73.86,0,0,1,12.32,30.89h-133A73.87,73.87,0,0,1,87.6,240.84L100.69,222H75.27A73.93,73.93,0,0,1,87.6,191.1l13.09-18.85H75.27a73.81,73.81,0,0,1,12.66-31.36L101.3,122H75.77A73.86,73.86,0,0,1,88.09,91.07l13.1-18.85H75.78c5.35-33.86,32.91-59.82,66-59.95m0-12c-43.62.14-78.95,37.67-78.95,84h15.4A86.91,86.91,0,0,0,62.84,134H78.13a86.92,86.92,0,0,0-15.78,50.29H77.74A87,87,0,0,0,62.35,234H77.74a87,87,0,0,0-15.39,49.74H221.23A86.91,86.91,0,0,0,205.83,234h15.4a86.91,86.91,0,0,0-15.4-49.74h15.4A86.92,86.92,0,0,0,205.45,134h15.28a87,87,0,0,0-15.39-49.74h15.39c0-46.28-35.32-83.81-78.94-83.95Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M184.61,41.73c0-18.6-19.37-33.68-43.26-33.68V75.42C165.24,75.42,184.61,60.33,184.61,41.73Z"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="11.91"
    />
    <path
      d="M98.09,109.1c0,18.6,19.37,33.68,43.26,33.68V75.42C117.46,75.42,98.09,90.5,98.09,109.1Z"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="11.91"
    />
    <path
      d="M141.35,142.78v67.37c23.89,0,43.26-15.08,43.26-33.68S165.24,142.78,141.35,142.78Z"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="11.91"
    />
    <path
      d="M98.09,243.83c0,18.61,19.37,33.69,43.26,33.69V210.15C117.46,210.15,98.09,225.23,98.09,243.83Z"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="11.91"
    />
    <path
      d="M271.46,11.65V58.49L183.31,11.65h88.15m12-12H135.15L283.46,78.46V-.35Z"
      fill="#231f20"
    />
    <path
      d="M100.22,11.74,12.06,58.58V11.74h88.16m48.15-12H.06V78.55L148.37-.26Z"
      fill="#231f20"
    />
    <path
      d="M271.46,148.67v46.84l-88.15-46.84h88.15m12-12H135.15l148.31,78.81V136.67Z"
      fill="#231f20"
    />
    <path
      d="M100.22,148.67,12.06,195.51V148.67h88.16m48.15-12H.06v78.81l148.31-78.81Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M141.53,98.22l9.78,82.36L156,219.76l17.94-35.14,43.85-85.91-5.07,41.2-3.29,26.74,22.07-15.45,16.64-11.65L175.66,271.21H108.88L36,139.76,52.36,151.2l22.07,15.45-3.29-26.74L66.22,100l42.33,84.24,17.69,35.19,4.93-39.07,10.36-82.11m.38-98.48-.07.2L119.27,178.83,45.87,32.76,59.23,141.38.35,100.14,101.82,283.21h80.93L283.46,100.14l-58.88,41.24L237.94,32.76,163.22,179.17,141.91-.26Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.24"
      y1="276.82"
      x2="141.24"
      y2="3.86"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.73,12c34.74,0,67.37,13,91.89,36.52C255,69.09,268,95.59,270.84,124.13H12.62c2.85-28.54,15.81-55,37.23-75.61C74.36,25,107,12,141.73,12m0-12C63.47,0,0,61,0,136.13H283.44C283.44,61,220,0,141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M208.68,136.36c8.32,20.08,13.26,43,14.48,67.2H60.64c1.22-24.21,6.15-47.12,14.48-67.2H208.68m7.81-12H67.31c-11.91,25.34-19,56.93-19,91.2H235.46c0-34.27-7.07-65.86-19-91.2Z"
      fill="#231f20"
    />
    <path
      d="M177.72,215.62a253.42,253.42,0,0,1,8.09,55.84H97.65a254.62,254.62,0,0,1,8.09-55.84h72m9-12H96.78c-7.13,22.21-11.36,49.86-11.36,79.84H198.05c0-30-4.23-57.63-11.36-79.84Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M163.48,108.51a155.87,155.87,0,0,1-6,35.61l-4.7,15.46h32.95a97.28,97.28,0,0,1-9.52,33.7l-8.76,17.37h47.07c-5.8,34.47-36.23,60.84-72.79,60.84s-67-26.37-72.79-60.84H116l-8.77-17.37a97.27,97.27,0,0,1-9.51-33.7h32.94L126,144.12a156.4,156.4,0,0,1-6-35.61h43.5m12.33-12H107.65c0,19.18,2.56,36.86,6.88,51.07H85.15c0,19.18,4.25,36.86,11.41,51.07H56c0,46.83,38.4,84.79,85.77,84.79s85.77-38,85.77-84.79H186.91c7.16-14.21,11.41-31.89,11.41-51.07H168.94c4.31-14.21,6.87-31.89,6.87-51.07Z"
      fill="#231f20"
    />
    <path
      d="M141.73,12c36.28,0,70.21,5.92,95.53,16.67,13.53,5.75,23.62,12.56,29.21,19.48H17c5.59-6.92,15.67-13.73,29.21-19.48C71.52,17.9,105.45,12,141.73,12m0-12C63.46,0,0,26.91,0,60.14H283.46C283.46,26.91,220,0,141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M141.73,60.15c36.28,0,70.21,5.92,95.53,16.67,13.53,5.74,23.62,12.56,29.21,19.48H17c5.59-6.92,15.67-13.74,29.21-19.48,25.31-10.75,59.24-16.67,95.52-16.67m0-12C63.46,48.14,0,75.08,0,108.31H283.46c0-33.23-63.45-60.17-141.73-60.17Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.72"
      y1="281.96"
      x2="141.72"
      y2="11.5"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="11.96"
    />
    <path
      d="M140.78,23.24,208.9,135.48H74.28l66.5-112.24M140.66,0,53.3,147.44H230.15L140.66,0Z"
      fill="#231f20"
    />
    <path
      d="M140.72,17.26,255.2,135.47H28L140.72,17.26M140.66,0,0,147.44H283.46L140.66,0Z"
      fill="#231f20"
    />
    <path
      d="M140.78,159.26,208.9,271.5H74.28l66.5-112.24M140.66,136,53.3,283.46H230.15L140.66,136Z"
      fill="#231f20"
    />
    <path
      d="M140.12,153.28l115,118.21H27.87L140.12,153.28M140,136,0,283.46H283.46L140,136Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M140.09,81.22,236,133H46.46l93.63-51.82M140,67.55,0,145H283.46L140,67.55Z"
      fill="#231f20"
    />
    <path
      d="M140.09,148.65l96.08,52.12H46.3l93.79-52.12M140,135,0,212.77H283.46L140,135Z"
      fill="#231f20"
    />
    <path
      d="M160.56,212.8v58.66H122.9V212.8h37.66m12-12H110.9v82.66h61.66V200.8Z"
      fill="#231f20"
    />
    <path
      d="M140.09,13.65,236,65.47H46.46l93.63-51.82M140,0,0,77.47H283.46L140,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M161.07,45.51v226H123.41V45.51h37.66m12-12H111.41v250h61.66V33.51Z"
      fill="#231f20"
    />
    <path
      d="M222.27,178.14c13.79,0,26.62,3.87,36.12,10.89A35.67,35.67,0,0,1,268,199.28H176.56A35.67,35.67,0,0,1,186.14,189c9.5-7,22.33-10.89,36.13-10.89m0-12c-33.8,0-61.2,20.24-61.2,45.2H283.46c0-25-27.4-45.2-61.19-45.2Z"
      fill="#231f20"
    />
    <path
      d="M61.57,178.19c20.5,0,38.56,8.86,45.95,21H15.62c7.38-12.18,25.45-21,46-21m0-12.08c-34,0-61.57,20.24-61.57,45.2H123.14c0-25-27.57-45.2-61.57-45.2Z"
      fill="#231f20"
    />
    <path
      d="M222.35,95.09c13.77,0,26.57,3.86,36.06,10.88A35.45,35.45,0,0,1,268,116.22H176.73A35.63,35.63,0,0,1,186.3,106c9.48-7,22.29-10.88,36-10.88m0-12c-33.75,0-61.11,20.23-61.11,45.2H283.46c0-25-27.36-45.2-61.11-45.2Z"
      fill="#231f20"
    />
    <path
      d="M61.66,95.13c20.53,0,38.63,8.86,46,21.05H15.63C23,104,41.12,95.13,61.66,95.13m0-12.07c-34,0-61.66,20.23-61.66,45.2H123.32c0-25-27.61-45.2-61.66-45.2Z"
      fill="#231f20"
    />
    <path
      d="M222.35,12c13.77,0,26.57,3.87,36.06,10.88A35.48,35.48,0,0,1,268,33.17H176.73a35.66,35.66,0,0,1,9.57-10.26c9.48-7,22.29-10.88,36-10.88m0-12c-33.75,0-61.11,20.24-61.11,45.2H283.46c0-25-27.36-45.2-61.11-45.2Z"
      fill="#231f20"
    />
    <path
      d="M61.66,12.08c20.53,0,38.63,8.86,46,21H15.63c7.39-12.18,25.49-21,46-21M61.66,0C27.61,0,0,20.24,0,45.2H123.32c0-25-27.61-45.2-61.66-45.2Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M13.89,190.25l106.29,80.63a63.51,63.51,0,0,1-8.84.58c-21.56,0-47.58-9.58-67.91-25C24,231.75,12,213.57,12,199a19.41,19.41,0,0,1,1.89-8.75M12.31,174C-11,191.64-.27,228.37,36.18,256c23.4,17.75,51.72,27.44,75.16,27.44,13.08,0,24.64-3,33-9.34L12.31,174Z"
      fill="#231f20"
    />
    <path
      d="M269.58,190.25a19.54,19.54,0,0,1,1.88,8.75c0,14.57-12,32.75-31.43,47.46-20.33,15.42-46.35,25-67.91,25a63.62,63.62,0,0,1-8.84-.58l106.3-80.63M271.15,174l-132,100.13c8.34,6.33,19.9,9.34,33,9.34,23.44,0,51.77-9.69,75.17-27.44,36.45-27.65,47.13-64.38,23.86-82Z"
      fill="#231f20"
    />
    <line
      x1="250.36"
      y1="17.9"
      x2="141.67"
      y2="274.12"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="141.91"
      y1="274.12"
      x2="141.91"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="33.45"
      y1="17.9"
      x2="142.15"
      y2="274.12"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <path
      d="M155,12V271.46H128.49V12H155M167,0H116.49V283.46H167V0Z"
      fill="#231f20"
    />
    <line
      x1="159.88"
      y1="28.2"
      x2="289.73"
      y2="115.89"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="159.88"
      y1="97.88"
      x2="289.73"
      y2="185.58"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="159.88"
      y1="167.57"
      x2="289.73"
      y2="255.27"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="123.58"
      y1="28.2"
      x2="-6.27"
      y2="115.89"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="123.58"
      y1="97.88"
      x2="-6.27"
      y2="185.58"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="123.58"
      y1="167.57"
      x2="-6.27"
      y2="255.27"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>,
  <>
    <line
      x1="141.58"
      y1="15.95"
      x2="141.58"
      y2="283.15"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <path
      d="M141.73,22.66,261.81,215H21.64L141.73,22.66m0-22.66L0,227H283.45L141.73,0Z"
      fill="#231f20"
    />
    <path
      d="M141.58,37.48l60,177.52h-120l60-177.52m0-37.48L64.86,227H218.3L141.58,0Z"
      fill="#231f20"
    />
  </>,
  <>
    <line
      x1="141.73"
      y1="283.15"
      x2="141.73"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <polyline
      points="-5.93 114.87 141.73 246.11 289.39 114.87"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="-5.93 54.7 141.73 185.95 289.39 54.7"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="-5.93 -5.46 141.73 125.78 289.39 -5.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
    <polyline
      points="57.67 -9.76 141.73 65.62 224.56 -8"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
      fillRule="evenodd"
    />
  </>,
  <>
    <path
      d="M182.08,12V82.31h41v74.41h48.36v65.53H12V156.72H60.37V82.31h41V12h80.7m12-12H89.38V70.31h-41v74.41H0v89.53H283.46V144.72H235.1V70.31h-41V0Z"
      fill="#231f20"
    />
    <path
      d="M163.51,234.4v37.06H120V234.4h43.55m12-12H108v61.06h67.55V222.4Z"
      fill="#231f20"
    />
  </>,
  <>
    <path
      d="M27.94,12A16,16,0,0,1,43.87,27.94V240.57H12V27.94A16,16,0,0,1,27.94,12m0-12A27.94,27.94,0,0,0,0,27.94V252.57H55.87V27.94A27.93,27.93,0,0,0,27.94,0Z"
      fill="#231f20"
    />
    <path
      d="M103.8,71.86a16,16,0,0,1,15.94,15.93V240.57H87.86V87.79A16,16,0,0,1,103.8,71.86m0-12h0A27.93,27.93,0,0,0,75.86,87.79V252.57h55.88V87.79A27.93,27.93,0,0,0,103.8,59.86Z"
      fill="#231f20"
    />
    <path
      d="M179.66,32.39A16,16,0,0,1,195.6,48.33V240.57H163.73V48.33a16,16,0,0,1,15.93-15.94m0-12h0a27.93,27.93,0,0,0-27.93,27.94V252.57H207.6V48.33a27.94,27.94,0,0,0-27.94-27.94Z"
      fill="#231f20"
    />
    <path
      d="M255.53,102.17a16,16,0,0,1,15.93,15.94V240.57H239.59V118.11a16,16,0,0,1,15.94-15.94m0-12h0a27.94,27.94,0,0,0-27.94,27.94V252.57h55.87V118.11a27.93,27.93,0,0,0-27.93-27.94Z"
      fill="#231f20"
    />
    <line
      x1="27.94"
      y1="247.57"
      x2="27.94"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="103.8"
      y1="247.57"
      x2="103.8"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="179.66"
      y1="247.57"
      x2="179.66"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
    <line
      x1="255.53"
      y1="247.57"
      x2="255.53"
      y2="283.46"
      fill="none"
      stroke="#231f20"
      strokeMiterlimit="10"
      strokeWidth="12"
    />
  </>
]
