import React, { Component } from 'react'

import illustrations from '../utils/illutrations'

import Tree from './Tree'

export default class Loader extends Component {
  state = {
    type: 0
  }
  componentDidMount = () => {
    this.interval = setInterval(() => {
      this.setState(prevState => ({
        type: illustrations.length < prevState.type + 1 ? prevState.type + 1 : 0
      }))
    }, 100)
  }
  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  render() {
    return (
      <div className="Loader">
        <div className="Loader__content">
          <Tree
            type={Math.floor(Math.random() * 20)}
            className="Loader__tree"
          />
        </div>
      </div>
    )
  }
}
