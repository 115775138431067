import React, { Component } from 'react'
import cc from 'classcat'

import Tree from './Tree'

export default class Card extends Component {
  render() {
    const { person, type, contributor } = this.props
    return (
      <div
        className={cc({
          Cards__item: true,
          'Cards__item--is-mine': contributor.id === person.id
        })}
      >
        <Tree type={type} className="Cards__tree" />
        <p className="Cards__name">{person.name}</p>
      </div>
    )
  }
}
