import React, { Component } from 'react'
import { PoseGroup } from 'react-pose'
import Snow from 'react-snow-effect'
import cc from 'classcat'

import firebase from './utils/firebase'
import illustrations from './utils/illutrations'

import Loader from './components/Loader'
import Form from './components/Form'
import Card from './components/Card'
import Header from './components/Header'
import Content from './components/Content'
import FormResponse from './components/FormResponse'

class App extends Component {
  state = {
    submitted: false,
    contributors: [],
    loading: true,
    contributor: {},
    snow: false,
    isEnglish: false
  }
  componentDidMount = () => {
    // Check if english
    if (window.location.pathname.includes('/en')) {
      this.setState({
        isEnglish: true
      })
    }
    // Check if user has already contributed
    this.getContributor()
    // Get data
    const contributorsRef = firebase.database().ref('contributors')
    contributorsRef.on('value', snapshot => {
      const data = snapshot.val()
      this.setState({
        loading: false,
        contributors: Object.keys(data)
          .reduce((res, key) => {
            res.push(
              Object.assign(
                {},
                {
                  id: key,
                  ...data[key]
                }
              )
            )
            return res
          }, [])
          .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
      })
    })
    setTimeout(() => {
      this.setState({ snow: true })
    }, 1500)
  }
  getContributor = () => {
    if (localStorage.getItem('person')) {
      this.setState({ contributor: JSON.parse(localStorage.getItem('person')) })
      this.setSubmitted()
    }
  }
  updateContributors = val => {
    this.setState({
      contributors: val
    })
  }
  setContributor = contributor => {
    this.setState({
      contributor
    })
  }
  setSubmitted = () => {
    this.setState({ submitted: true })
  }
  setTreeType = i => {
    const index = this.state.contributors.length - i
    return index % illustrations.length
  }
  togglePartyMode = () => {
    this.setState(prevState => ({ party: !prevState.party }))
  }
  render() {
    const {
      loading,
      submitted,
      contributors,
      contributor,
      party,
      snow,
      isEnglish
    } = this.state
    return (
      <main className="Page">
        {snow && <Snow />}
        <Header togglePartyMode={this.togglePartyMode} party={party} />
        {loading && <Loader />}
        {!loading && (
          <>
            {!submitted && (
              <>
                <Content isEnglish={isEnglish} />
                <Form
                  isEnglish={isEnglish}
                  setSubmitted={this.setSubmitted}
                  getContributor={this.getContributor}
                  setContributor={this.setContributor}
                />
              </>
            )}
            {submitted && contributors.length > 0 && (
              <FormResponse
                isEnglish={isEnglish}
                contributors={contributors}
                contributor={contributor}
              />
            )}
            <PoseGroup>
              <div
                className={cc({ Cards: true, 'Cards--is-party': party })}
                key="cards"
              >
                {contributors &&
                  contributors.map((person, i) => (
                    <Card
                      contributor={contributor}
                      key={person.id}
                      person={person}
                      type={this.setTreeType(i)}
                    />
                  ))}
              </div>
            </PoseGroup>
          </>
        )}
      </main>
    )
  }
}

export default App
