import React, { Component } from 'react'

export default class Content extends Component {
  render() {
    const { isEnglish } = this.props
    return (
      <div className="container">
        {!isEnglish && (
          <>
            <p>
              Bli med å skape en skog! Plant ditt digitale tre – for hvert tre
              gir vi i Heydays en gave til Regnskogfondet som redder 10 000 m²
              regnskog i året.
            </p>
            <p className="small">
              Litt info: Vårt klima er helt avhengig av regnskogen fordi den
              lagrer enorme mengder karbon og er med på å regulere verdens
              nedbørsmønstre. Det har tatt oss mennesker bare 60 år å ødelegge
              halvparten av det kloden har brukt nesten 100 millioner år å bygge
              opp. Å redde regnskogen er en ekstremt viktig kamp å ta – spesielt
              med tanke på miljøet. Les mer om Regnskogfondets arbeid på{' '}
              <a href="https://regnskog.no">regnskog.no</a>.
            </p>
          </>
        )}
        {isEnglish && (
          <>
            <p>
              Join us in creating a forest! Plant your digital tree—for every
              tree planted, Heydays will give Rainforest Foundation Norway an
              amount equal to save 10.000 m² of rainforest (per year).
            </p>
            <p className="small">
              Some info: Our climate is dependant on the rainforest for its
              capacity of storing great amounts of carbon and ability to
              regulate the world´s rainfall patterns. It has taken us only 60
              years to destroy what the earth has spent almost 100 million years
              building. Saving the rainforest is an immense and important battle
              that we have to endeavor to save our environment. Read more about
              Rainforest Foundation Norway´s work at{' '}
              <a href="https://regnskog.no">regnskog.no</a>.
            </p>
          </>
        )}
      </div>
    )
  }
}
