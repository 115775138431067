import * as firebase from 'firebase/app'
import 'firebase/database'

const config = {
  apiKey: 'AIzaSyB8GlPEZUr9DvY7_MbISOHwQs04znOgwMw',
  authDomain: 'julekort2018-42929.firebaseapp.com',
  databaseURL: 'https://julekort2018-42929.firebaseio.com',
  projectId: 'julekort2018-42929',
  storageBucket: 'julekort2018-42929.appspot.com',
  messagingSenderId: '1022528714797'
}

export default firebase.initializeApp(config)

export const addContributor = person => {
  firebase
    .database()
    .ref('contributors/' + person.id)
    .set(person, err => {
      if (err) {
        return {
          err,
          msg: 'Something went wrong'
        }
      } else {
        return {
          err: null,
          msg: 'Sucessfully added to contributors'
        }
      }
    })
    .then(res => {
      localStorage.setItem('person', JSON.stringify(person))
      return res
    })
}

const contributorsRef = firebase.database().ref('contributors/')
export const contributors = contributorsRef.on('value', function(snapshot) {
  return snapshot.val()
})
