import React, { Component } from 'react'
import illustrations from '../utils/illutrations'

export default class Tree extends Component {
  render() {
    const { type } = this.props
    if (illustrations[type]) {
      return (
        <svg
          {...this.props}
          viewBox="0 0 283.46 283.46"
          // viewBox="0 0 320 315" figma exports
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {illustrations[type]}
        </svg>
      )
    } else {
      return <h2>No tree found ¯\_(ツ)_/¯</h2>
    }
  }
}
