import React, { Component } from 'react'
import PartyMode from './PartyMode'

export default class Header extends Component {
  render() {
    const { togglePartyMode, party } = this.props
    return (
      <div className="Header">
        <a href="/">
          <svg
            className="Logo"
            width="32"
            height="40"
            viewBox="0 0 32 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.1819 14.5711H9.49204V0.321289H0V39.4465H9.49204V23.7761H22.1819V39.4465H31.6735V0.321289H22.1819V14.5711Z"
              fill="black"
            />
          </svg>
        </a>
        {/* <p className="small">Heydays julekort 2018</p> */}
        <PartyMode togglePartyMode={togglePartyMode} party={party} />
      </div>
    )
  }
}
